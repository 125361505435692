"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const {
    PAGE_LOAD,
    PRODUCT_CLICK,
    PRODUCT_SWATCH_CLICK,
    PRODUCT_LIST_UPDATE
} = require("../models/Category");

class CategoryAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof CategoryAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-data-ppc", "js-a-page-instance"];
        super("CategoryAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
    * Overrides the baseInterceptedEvents method to conditionally execute based on the presence of PPC elements.
    * If PPC elements are detected, the baseInterceptedEvents functionality is skipped.
    * Otherwise, the original baseEvents method from BaseAnalytics is called.
    */
    baseInterceptedEvents() {
        const isPPC = document.getElementsByClassName("js-ppc-plp").length > 0;

        if (isPPC) {
            // Skip the baseInterceptedEvents functionality for PPC pages
        } else {
            // Call the original baseEvents method from BaseAnalytics
            super.baseInterceptedEvents();
        }
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof CategoryAnalytics
     */
    collect() {
        const PRODUCT_PAGE_DATA = this.preparePageProductData();

        this.prepareDOMElements();
        this.getPageData(PRODUCT_PAGE_DATA);
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        // this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    preparePageProductData() {
        let preparedObject = {};
        let productIds = [];
        let facets = [];
        let totalResults = "";
        let resultsPerPage = "20";

        const sortTypeObj = document.getElementById("sort-order");
        const resultsData = document.getElementById("js-a-page-results");
        const currentPageData = document.getElementsByClassName("js-bottom-pagination");
        let featuredProducts = [];
        const isPPC = document.getElementsByClassName("js-ppc-plp").length > 0;
        let productIdList = Array.from(document.querySelectorAll(".search-results .js-a-tile-data"));

        if (isPPC) {
            featuredProducts = Array.from(document.querySelectorAll(".js-a-page-product-data"));
            productIdList = featuredProducts.concat(productIdList);
        }

        const facetList = document.getElementsByClassName("js-a-refinement");
        const sortType = sortTypeObj ? sortTypeObj.options[sortTypeObj.selectedIndex].text : "";
        const currentPageValue = currentPageData && currentPageData.length > 0 ? currentPageData[0].dataset.currentPage : "1";

        if (resultsData) {
            const resultsPerPageObj = resultsData.getElementsByClassName("js-a-page-results-per-page");

            totalResults = resultsData.dataset.totalResults;

            if (resultsPerPageObj.length > 0) {
                resultsPerPage = resultsPerPageObj[0].value;
            }
        }

        facets = this.getFacetsData(facetList);
        productIds = this.getProductData(productIdList);

        preparedObject = {
            currentPage: currentPageValue,
            sortType: sortType,
            totalResults: totalResults,
            resultsPerPage: resultsPerPage,
            products: productIds,
            facets: facets,
        };

        return preparedObject;
    }

    pageEvents() {

    }

    pageInterceptedEvents() {
        this.addNativeEventListeners("refinementUpdate", window, this.productListUpdate);
    }

    pageDelayedEvents() {
        this.addEventWatcher("click", document.getElementsByClassName("js-a-page-product-list"), ".js-a-product-click", target => this.productClick(target));
        this.addEventWatcher("click", document.getElementsByClassName("js-a-page-product-list"), ".js-a-product-swatch-click", target => this.productSwatchClick(target));
    }

    productClick(target) {
        const productDataObj = target.closest(".js-a-tile-data");

        let position = "";

        if (productDataObj && productDataObj.dataset.position) {
            position = productDataObj.dataset.position;
        } else {
            position = productDataObj.closest(".product-tile-wrapper").dataset.position;
        }

        let productData = {
            products: [
                {
                    id: productDataObj ? productDataObj.dataset.id : "",
                    position: position
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_CLICK, productData);
    }

    productSwatchClick(target) {
        let productData = {
            products: [
                {
                    color: target.dataset.color,
                    id: target.dataset.id,
                    bopisStatus: target.dataset.bopisStatus,
                    onlineStatus: target.dataset.onlineStatus,
                    sku: target.dataset.sku
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_SWATCH_CLICK, productData);
    }

    productListUpdate() {
        const domData = this.preparePageProductData();

        this.prepareSingleEvent(PRODUCT_LIST_UPDATE, domData);
    }

    getProductData(parent) {
        let results = [];

        for (let product of parent) {
            var dataset = product.dataset;
            results.push({
                availableForStorePickup: dataset.availableForStorePickup,
                bopisStatus: dataset.bopisStatus,
                categoryNames: dataset.categoryNames ? JSON.parse(dataset.categoryNames) : [],
                clearanceTag: dataset.clearanceTag,
                id: dataset.id ? dataset.id : dataset.pid,
                memberDealTag: dataset.memberDealTag,
                merchandisingCallouts: dataset.merchandisingCallouts ? JSON.parse(dataset.merchandisingCallouts) : [],
                multipleSizesTag: dataset.multipleSizesTag,
                newTag: dataset.newTag,
                onlineStatus: dataset.onlineStatus,
                price: dataset.price,
                promotionCallouts: dataset.promotionCallouts ? JSON.parse(dataset.promotionCallouts) : [],
                rating: dataset.rating,
                reviews: dataset.reviews,
                saleTag: dataset.saleTag,
                stsEligible: dataset.stsEligible
            });
        }

        return results;
    }

    getFacetsData(parent) {
        let results = [];

        for (let facet of parent) {
            const facetValuesObjs = facet.getElementsByClassName("js-a-refinement-selected");
            let facetValues = [];

            if (facetValuesObjs.length > 0) {
                for (let facetValueObj of facetValuesObjs) {
                    facetValues.push(facetValueObj.dataset.facetValue);
                }

                results.push({
                    name: facet.dataset.facetName,
                    values: facetValues
                });
            }
        }

        return results;
    }

    /**
    * Handles the store change event with specific behavior for PPC pages.
    * This method checks if the current page is a PPC (Pay-Per-Click) page by 
    * looking for elements with the class "js-ppc-plp". If such elements are found, 
    * the function skips executing the store change event functionality. Otherwise, 
    * it calls the original storeChangeEvent method from the BaseAnalytics class.
    */
    storeChangeEvent() {
        const isPPC = document.getElementsByClassName("js-ppc-plp").length > 0;

        if (!isPPC) {
            // Call the original storeChangeEvent method from BaseAnalytics
            super.storeChangeEvent();
        }
    }
}

module.exports = CategoryAnalytics;
