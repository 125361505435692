"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const {
    PAGE_LOAD,
    PRODUCT_CLICK,
    PRODUCT_SWATCH_CLICK,
    AUTO_SUGGEST_CLICK,
    PRODUCT_LIST_UPDATE,
} = require("../models/Search");

class SearchAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof SearchAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        super("SearchAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     * Check whether page has or not results since it's sharing templates
     *
     * @memberof SearchAnalytics
     */
    collect() {
        const pageResultsData = document.getElementById("js-a-search-has-results");
        const pageHasResults = pageResultsData ? pageResultsData.dataset.pageHasResults : "false";

        if (pageHasResults == "true") {
            const PRODUCT_PAGE_DATA = this.preparePageProductData();
            this.getPageData(PRODUCT_PAGE_DATA);
        } else {
            this.getPageData();
        }

        this.prepareDOMElements();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    preparePageProductData() {
        let preparedObject = {};
        let productIds = [];
        let facets = [];
        let totalResults = "";
        let resultsPerPage = "20";

        const sortTypeObj = document.getElementById("sort-order");
        const resultsData = document.getElementById("js-a-page-results");
        const currentPageData = document.getElementsByClassName("js-bottom-pagination");
        const productIdList = document.getElementsByClassName("js-a-tile-data");
        const facetList = document.getElementsByClassName("js-a-refinement");

        const sortType = sortTypeObj ? sortTypeObj.options[sortTypeObj.selectedIndex].text : "";
        const currentPageValue = currentPageData && currentPageData.length > 0 ? currentPageData[0].dataset.currentPage : "1";


        if (resultsData) {
            const resultsPerPageObj = resultsData.getElementsByClassName("js-a-page-results-per-page");

            totalResults = resultsData.dataset.totalResults;

            if (resultsPerPageObj.length > 0) {
                resultsPerPage = resultsPerPageObj[0].value;
            }
        }

        facets = this.getFacetsData(facetList);
        productIds = this.getProductData(productIdList);

        preparedObject = {
            currentPage: currentPageValue,
            sortType: sortType,
            totalResults: totalResults,
            resultsPerPage: resultsPerPage,
            products: productIds,
            facets: facets,
        };

        return preparedObject;
    }

    pageEvents() {
        this.addCollectionNativeEventListeners("click", document.getElementsByClassName("js-a-search-suggestion-click"), event => this.autoSuggestClick(event));
    }

    pageInterceptedEvents() {
        this.addNativeEventListeners("refinementUpdate", window, this.productListUpdate);
    }

    pageDelayedEvents() {
        this.addEventWatcher("click", document.getElementsByClassName("js-a-page-product-list"), ".js-a-product-click", target => this.productClick(target));
        this.addEventWatcher("click", document.getElementsByClassName("js-a-page-product-list"), ".js-a-product-swatch-click", target => this.productSwatchClick(target));
    }

    productClick(target) {
        const productDataObj = target.closest(".js-a-tile-data");
        let productData = {
            products: [
                {
                    id: productDataObj ? productDataObj.dataset.id : "",
                    position: productDataObj ? productDataObj.dataset.position: ""
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_CLICK, productData);
    }

    productSwatchClick(target) {
        let productData = {
            products: [
                {
                    color: target.dataset.color,
                    id: target.dataset.id,
                    bopisStatus: target.dataset.bopisStatus,
                    onlineStatus: target.dataset.onlineStatus,
                    sku: target.dataset.sku
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_SWATCH_CLICK, productData);
    }

    autoSuggestClick(e) {
        const autoSuggestTermObj = document.getElementById("js-a-search-term");

        const autoSuggestLink = e.currentTarget.dataset.autoSuggestLink;
        const autoSuggestTerm = autoSuggestTermObj ? autoSuggestTermObj.dataset.autoSuggestTerm : "";

        let productData = {
            autoSuggestLink: autoSuggestLink,
            autoSuggestTerm: autoSuggestTerm,
        };

        this.prepareSingleEvent(AUTO_SUGGEST_CLICK, productData);
    }

    productListUpdate() {
        const domData = this.preparePageProductData();

        this.prepareSingleEvent(PRODUCT_LIST_UPDATE, domData);
    }

    getProductData(parent) {
        let results = [];
        for (let product of parent) {
            var dataset = product.dataset;
            results.push({
                availableForStorePickup: dataset.availableForStorePickup,
                bopisStatus: dataset.bopisStatus,
                categoryNames: dataset.categoryNames ? JSON.parse(dataset.categoryNames) : [],
                clearanceTag: dataset.clearanceTag,
                id: dataset.id ? dataset.id : dataset.pid,
                memberDealTag: dataset.memberDealTag,
                merchandisingCallouts: dataset.merchandisingCallouts ? JSON.parse(dataset.merchandisingCallouts) : [],
                multipleSizesTag: dataset.multipleSizesTag,
                newTag: dataset.newTag,
                onlineStatus: dataset.onlineStatus,
                price: dataset.price,
                promotionCallouts: dataset.promotionCallouts ? JSON.parse(dataset.promotionCallouts) : [],
                rating: dataset.rating,
                reviews: dataset.reviews,
                saleTag: dataset.saleTag,
                stsEligible: dataset.stsEligible
            });
        }

        return results;
    }

    getFacetsData(parent) {
        let results = [];

        for (let facet of parent) {
            const facetValuesObjs = facet.getElementsByClassName("js-a-refinement-selected");
            let facetValues = [];

            if (facetValuesObjs.length > 0) {
                for (let facetValueObj of facetValuesObjs) {
                    facetValues.push(facetValueObj.dataset.facetValue);
                }

                results.push({
                    name: facet.dataset.facetName,
                    values: facetValues
                });
            }
        }

        return results;
    }
}

module.exports = SearchAnalytics;
