const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
    PRODUCT_DATA,
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        },
        page: {
            ...PAGE_INSTANCE,
            type: "order confirmation",
        },
        order: {
            customerState: "",
            discountAmount: "",
            giftCardAmount: "",
            orderId: "",
            paymentMethod: [],
            pickupStoreName: "",
            promoCodes: [
                {
                    code: "",
                    name: "",
                    singleUsePromoCode: "",
                }
            ],
            shippingAmount: "",
            shippingDiscountAmount: "",
            subtotal: "",
            taxAmount: "",
            total: "",
            coloradoFeeAmount: "",
            emailAddressSha256: "",
            phoneNumberSha256: ""
        },
        products: [
            {
                ...PRODUCT_DATA,
                categoryNames: [],
                fulfillmentMethod: "",
                freeShippingFlag: "",
                discountAmount: "",
                markdownAmount: "",
                memberDiscount: "",
                shippingSurcharge: "",
            }
        ]
    }
};

module.exports = {
    PAGE_LOAD,
};
