const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE
        },
        page: {
            ...PAGE_INSTANCE,
            type: "gateway",
            categoryId: "",
            productCategories: []
        }
    }
};

module.exports = {
    PAGE_LOAD,
};
