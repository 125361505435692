const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
} = require("./Base");

const PAGE_LOAD_PPC = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE
        },
        page: {
            ...PAGE_INSTANCE,
            type: "ppc",
            hasVideo: "",
            subType: "",
            categoryId: "",
            productCategories: [],
        },
        products: [
            {
                bopisStatus: "",
                availableForStorePickup: "",
                categoryNames: [],
                collectionId: "",
                freeShippingFlag: "",
                id: "",
                kitId: "",
                memberDealTag: "",
                multipleSizesTag: "",
                newTag: "",
                onlineStatus: "",
                price: "",
                quantity: "",
                rating: "",
                reviews: "",
                saleTag: "",
                shippingSurcharge: "",
                sku: "",
                skus: [],
                merchandisingCallouts: [],
                promotionCallouts: []
            }
        ]
    }
};

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE
        },
        page: {
            ...PAGE_INSTANCE,
            type: "pdp",
            hasVideo: "",
            subType: "",
        },
        products: [
            {
                bopisStatus: "",
                availableForStorePickup: "",
                categoryNames: [],
                collectionId: "",
                freeShippingFlag: "",
                id: "",
                kitId: "",
                memberDealTag: "",
                multipleSizesTag: "",
                newTag: "",
                onlineStatus: "",
                price: "",
                quantity: "",
                rating: "",
                reviews: "",
                saleTag: "",
                shippingSurcharge: "",
                sku: "",
                skus: [],
                merchandisingCallouts: [],
                promotionCallouts: []
            }
        ]
    }
};

const PRODUCT_SWATCH_CLICK = {
    eventName: "productSwatchClick",
    eventData: {
        products: [
            {
                color: "",
                id: "",
                bopisStatus: "",
                onlineStatus: "",
                sku: ""
            }
        ]
    }
};

const PRODUCT_BOPIS_CLICK = {
    eventName: "bopisFulfillmentChange",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_SHIPMENT_CLICK = {
    eventName: "shipFulfillmentChange",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_ALERT_STOCK = {
    eventName: "stockAlert",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_ALERT_PRICE = {
    eventName: "priceAlert",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_ALERT_ERROR = {
    eventName: "alertError",
    eventData: {
        error: {
            type: ""
        },
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_REVIEW_START = {
    eventName: "productReviewStart",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

// To review as step is complete in a separate page
const PRODUCT_REVIEW_COMPLETE = {
    eventName: "productReviewComplete",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_IMAGE_SLIDER = {
    eventName: "altImageSlider",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const PRODUCT_VIDEO_SLIDER = {
    eventName: "loadYouTubePlayer",
    eventData: {
        products: [
            {
                id: "",
            }
        ],
        videoId: ""
    }
};

const PRODUCT_ACCORDION_CLICK = {
    eventName: "pdpAccordionClick",
    eventData: {
        products: [
            {
                id: "",
            }
        ],
        sectionName: ""
    }
};

const PRODUCT_UGC_CLICK = {
    eventName: "ugcClick",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

module.exports = {
    PAGE_LOAD,
    PAGE_LOAD_PPC,
    PRODUCT_SWATCH_CLICK,
    PRODUCT_BOPIS_CLICK,
    PRODUCT_SHIPMENT_CLICK,
    PRODUCT_ALERT_STOCK,
    PRODUCT_ALERT_PRICE,
    PRODUCT_ALERT_ERROR,
    PRODUCT_REVIEW_START,
    PRODUCT_REVIEW_COMPLETE,
    PRODUCT_IMAGE_SLIDER,
    PRODUCT_VIDEO_SLIDER,
    PRODUCT_ACCORDION_CLICK,
    PRODUCT_UGC_CLICK
};
