"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const { 
    PAGE_LOAD,
    CART_UPDATE,
    CART_ERROR,
    // CART_EMPTY,
    // MOVE_TO_FAVORITES,
    EDIT_PRODUCT,
    CHANGE_TO_BOPIS,
    CHANGE_TO_SHIPPING,
} = require("../models/Cart");

class CartAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof CartAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        super("CartAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof CartAnalytics
     */
    collect() {
        this.prepareDOMElements();
        this.getPageData();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    pageEvents() {

    }

    pageInterceptedEvents() {
        this.addNativeEventListeners("updateCart", window, event => this.cartUpdate(event));
        this.addNativeEventListeners("errorCart", window, event => this.cartError(event));
        this.addNativeEventListeners("productEdit", window, event => this.editProduct(event));
        this.addNativeEventListeners("shipmentUpdate", window, event => this.productShipmentClick(event));
    }

    pageDelayedEvents() {

    }

    cartUpdate(e) {
        let domData = {
            ...e.detail
        };

        this.prepareSingleEvent(CART_UPDATE, domData);
    }

    cartError(e) {
        let domData = {
            type: e.detail,
        };

        this.prepareSingleEvent(CART_ERROR, domData);
    }

    editProduct(e) {
        let domData = {
            ...e.detail,
        };

        this.prepareSingleEvent(EDIT_PRODUCT, domData);
    }

    productShipmentClick(e) {
        const eventData = e.detail;

        let domData = {
            ...eventData.productData
        };

        if (eventData.type == "shipToStore") {
            this.prepareSingleEvent(CHANGE_TO_BOPIS, domData);
        } else {
            this.prepareSingleEvent(CHANGE_TO_SHIPPING, domData);
        }
    }
}

module.exports = CartAnalytics;
