const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
    PRODUCT_DATA,
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        },
        page: {
            ...PAGE_INSTANCE,
            type: "account",
            name: "buy again",
        }
    }
};

const BUY_AGAIN_FILTER = {
    eventName: "buyAgainFilter",
    eventData: {
        page: {
            buyAgainFilter: "",
        }
    }
};

const BUY_AGAIN_CLICK = {
    eventName: "buyAgainClick",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const CART_ADD = {
    eventName: "cartAdd",
    eventData: {
        products: [
            {
                ...PRODUCT_DATA,
                addLocation: "buy again",
                bopisType: "",
                fulfillmentMethod: "",
                pickupStore: "",
            }
        ]
    }
};

const CART_ERROR = {
    eventName: "cartAddError",
    eventData: {
        error: {
            type: "",
        }
    }
};

module.exports = {
    PAGE_LOAD,
    BUY_AGAIN_FILTER,
    BUY_AGAIN_CLICK,
    CART_ADD,
    CART_ERROR,
};
