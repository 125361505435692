"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const {
    PAGE_LOAD,
    PAGE_LOAD_PPC,
    PRODUCT_SWATCH_CLICK,
    PRODUCT_BOPIS_CLICK,
    PRODUCT_SHIPMENT_CLICK,
    PRODUCT_ALERT_STOCK,
    PRODUCT_ALERT_PRICE,
    PRODUCT_ALERT_ERROR,
    PRODUCT_REVIEW_START,
    PRODUCT_IMAGE_SLIDER,
    PRODUCT_VIDEO_SLIDER,
    PRODUCT_ACCORDION_CLICK,
    PRODUCT_UGC_CLICK
} = require("../models/Pdp");

class PDPAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof PDPAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        const isPPC = document.getElementsByClassName("js-ppc-plp ").length > 0 ? true : false;
        const pageModel = isPPC ? PAGE_LOAD_PPC : PAGE_LOAD;

        super("PdpAnalytics", DOM_PAGE_DATA, pageModel);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof PDPAnalytics
     */
    collect() {
        const PRODUCT_PAGE_DATA = this.preparePageProductData();

        this.prepareDOMElements();
        this.getPageData(PRODUCT_PAGE_DATA);
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    preparePageProductData() {
        let preparedObject = {};
        const mainProductObj = document.getElementById("js-a-page-product-data");
        const productCollection = document.getElementsByClassName("js-a-page-collection-data");
        const hasVideo = document.getElementsByClassName("js-a-video").length > 0 ? "true" : "false";
        const hasCollection = document.getElementById("js-a-pdp-collection").dataset.hasCollection;

        preparedObject = {
            hasVideo: hasVideo,
            products: [],
        };

        if (hasCollection == "true" && productCollection.length > 0) {
            for (let product of productCollection) {
                preparedObject.products.push(this.retrieveProductData(product));
            }
        } else {
            preparedObject.products.push(this.retrieveProductData(mainProductObj));
        }

        return preparedObject;
    }

    pageEvents() {
        this.addCollectionNativeEventListeners("change", document.getElementsByClassName("js-a-product-delivery-shipment"), event => this.productShipmentClick(event));
        this.addCollectionNativeEventListeners("change", document.getElementsByClassName("js-a-product-delivery-bopis"), event => this.productBopisClick(event));
        this.addCollectionNativeEventListeners("click", document.getElementsByClassName("js-a-product-accordion"), event => this.productAccordionClick(event));
    }

    pageInterceptedEvents() {
        this.addNativeEventListeners("productAlertSuccess", window, event => this.alertSuccess(event));
        this.addNativeEventListeners("productAlertError", window, event => this.alertFailure(event));
        this.addNativeEventListeners("productImageSlider", window, this.productImageSlide);
        this.addNativeEventListeners("productVideoSlider", window, event => this.productVideoSlide(event));
        this.addNativeEventListeners("pdpSwatchSelect", window, event => this.prepareSingleEvent(PRODUCT_SWATCH_CLICK, event.detail));
        this.addNativeEventListeners("pdpUgcClick", window, this.productUgcClick);
    }

    pageDelayedEvents() {
        this.addEventWatcher("click", document.getElementsByClassName("js-a-product-review"), ".pr-snippet-write-review-link", target => this.productReviewStart(target));
    }

    productShipmentClick(e) {
        let domData = {
            products: [
                {
                    id: e.currentTarget.dataset.id
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_SHIPMENT_CLICK, domData);
    }

    productBopisClick(e) {
        let domData = {
            products: [
                {
                    id: e.currentTarget.dataset.id
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_BOPIS_CLICK, domData);
    }

    /**
     * Prepare alert success event and check type
     *
     * @memberof PDPAnalytics
     */
    alertSuccess(e) {
        let domData = {
            type: e.detail.data,
            products: [
                {
                    id: e.detail.pid
                }
            ]
        };

        if (e.detail.alertType == "price") {
            this.prepareSingleEvent(PRODUCT_ALERT_PRICE, domData);
        }

        if (e.detail.alertType == "stock") {
            this.prepareSingleEvent(PRODUCT_ALERT_STOCK, domData);
        }
    }

    /**
     * Prepare alert failure event
     *
     * @memberof PDPAnalytics
     */
    alertFailure(e) {
        let domData = {
            type: e.detail.data,
            products: [
                {
                    id: e.detail.pid
                }
            ]
        };

        this.prepareSingleEvent(PRODUCT_ALERT_ERROR, domData);
    }

    /**
     * Prepare review start event
     *
     * @memberof PDPAnalytics
     */
    productReviewStart() {
        const reviewParent = document.getElementsByClassName("js-a-product-review")[0];

        if (reviewParent) {
            let domData = {
                products: [
                    {
                        id: reviewParent.dataset.id
                    }
                ]
            };

            this.prepareSingleEvent(PRODUCT_REVIEW_START, domData);
        }
    }

    /**
     * Prepare review start event
     *
     * @memberof PDPAnalytics
     */
    productImageSlide() {
        const sliderParent = document.getElementsByClassName("js-a-product-slider")[0];

        if (sliderParent) {
            let domData = {
                products: [
                    {
                        id: sliderParent.dataset.id
                    }
                ]
            };

            this.prepareSingleEvent(PRODUCT_IMAGE_SLIDER, domData);
        }
    }

    /**
     * Prepare video slider start event
     *
     * @memberof PDPAnalytics
     */
    productVideoSlide(e) {
        const sliderParent = document.getElementsByClassName("js-a-product-slider")[0];

        if (sliderParent) {
            let domData = {
                products: [
                    {
                        id: sliderParent.dataset.id
                    }
                ],
                videoId: e.detail.videoId
            };

            this.prepareSingleEvent(PRODUCT_VIDEO_SLIDER, domData);
        }
    }

    productAccordionClick(e) {
        let domData = {
            products: [
                {
                    id: e.currentTarget.dataset.id
                }
            ],
            sectionName: e.currentTarget.dataset.sectionName
        };

        this.prepareSingleEvent(PRODUCT_ACCORDION_CLICK, domData);
    }

    retrieveProductData(productObj) {
        let productData = {
            ...productObj.dataset
        };

        productData.categoryNames = JSON.parse(productData.categoryNames);
        productData.skus = JSON.parse(productData.skus);
        productData.merchandisingCallouts = JSON.parse(productData.merchandisingCallouts);
        productData.promotionCallouts = JSON.parse(productData.promotionCallouts);

        return productData;
    }

    productUgcClick() {
        const parent = document.getElementsByClassName("js-ugc-container")[0];

        if (parent) {
            let domData = {
                products: [
                    {
                        id: parent.dataset.id
                    }
                ]
            };

            this.prepareSingleEvent(PRODUCT_UGC_CLICK, domData);
        }
    }
}

module.exports = PDPAnalytics;
