const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
} = require("./Base");

const PAGE_SEARCH_PAGE_DATA = {
    currentPage: "",
    facets: [
        {
            name: "",
            values: []
        }
    ],
    searchType: "",
    sortType: "",
    resultsPerPage: "",
    totalResults: ""
};

const PAGE_SEARCH_PRODUCT_DATA = {
    id: ""
};

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        },
        page: {
            ...PAGE_INSTANCE,
            type: "search results",
        },
        productList: {
            ...PAGE_SEARCH_PAGE_DATA
        },
        products: [
            {
                ...PAGE_SEARCH_PRODUCT_DATA
            }
        ]
    }
};

const PRODUCT_CLICK = {
    eventName: "productClick",
    eventData: {
        products : [
            {
                id: "",
                position: "",
            }
        ]
    }
};

const PRODUCT_SWATCH_CLICK = {
    eventName: "productSwatchClick",
    eventData: {
        products: [
            {
                color: "",
                id: "",
                bopisStatus: "",
                onlineStatus: "",
                sku: ""
            }
        ]
    }
};

const AUTO_SUGGEST_CLICK = {
    eventName: "autoSuggestClick",
    eventData: {
        search: {
            autoSuggestLink: "",
            autoSuggestTerm: "",
            autoSuggestType: "did you mean"
        }
    }
};

const PRODUCT_LIST_UPDATE = {
    eventName: "productListUpdate",
    eventData: {
        productList: {
            currentPage: "",
            facets: [
                {
                    name: "",
                    values: []
                }
            ],
            searchType: "",
            sortType: "",
            resultsPerPage: "",
            totalResults: ""
        },
        products: [
            {
                id: ""
            }
        ]
    }
};

module.exports = {
    PAGE_LOAD,
    PRODUCT_CLICK,
    PRODUCT_SWATCH_CLICK,
    AUTO_SUGGEST_CLICK,
    PRODUCT_LIST_UPDATE,
};
