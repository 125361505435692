"use strict";
const BaseAnalytics = require("./BaseAnalytics");

const {
    PAGE_LOAD
} = require("../models/Error");

class ErrorAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof ErrorAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        super("ErrorAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof ErrorAnalytics
     */
    collect() {
        this.prepareDOMElements();
        this.getPageData();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.preparePageDesignerData();
        // this.pageEvents();
        // this.pageInterceptedEvents();
        // this.pageDelayedEvents();
    }

    pageEvents() {
    }

    pageInterceptedEvents() {
    }

    pageDelayedEvents() {
    }
}

module.exports = ErrorAnalytics;
