module.exports = {
    BaseAnalytics: require("./base/BaseAnalytics"),
    BackupAnalytics: require("./base/BackupAnalytics"),
    StartupAnalytics: require("./pagedesigner/StartupAnalytics"),
    GatewayAnalytics: require("./pagedesigner/GatewayAnalytics"),
    InspirationAnalytics: require("./pagedesigner/InspirationAnalytics"),
    StaticAnalytics: require("./pagedesigner/StaticAnalytics"),
    CustomerServiceAnalytics: require("./pagedesigner/CustomerServiceAnalytics"),
    CategoryAnalytics: require("./search/CategoryAnalytics"),
    SearchAnalytics: require("./search/SearchAnalytics"),
    PdpAnalytics: require("./pdp/PdpAnalytics"),
    ReviewAnalytics: require("./pdp/ReviewAnalytics"),
    CartAnalytics: require("./checkout/CartAnalytics"),
    CheckoutAnalytics: require("./checkout/CheckoutAnalytics"),
    OrderConfirmationAnalytics: require("./checkout/OrderConfirmationAnalytics"),
    FavoritesAnalytics: require("./account/FavoritesAnalytics"),
    BuyAgainAnalytics: require("./account/BuyAgainAnalytics"),
    OrderHistoryAnalytics: require("./account/OrderHistoryAnalytics"),
    OrderDetailAnalytics: require("./account/OrderDetailAnalytics"),
    OtherAccountAnalytics: require("./account/OtherAccountAnalytics"),
    ErrorAnalytics: require("./base/ErrorAnalytics"),
    PlccAnalytics: require("./plcc/PlccAnalytics"),
};
