const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        },
        page: {
            ...PAGE_INSTANCE,
            type: "account",
            name: "order history",
        }
    }
};

const ORDER_TRACK = {
    eventName: "trackOrder",
    eventData: {}
};

const ORDER_RETURN = {
    eventName: "returnOrder",
    eventData: {}
};

module.exports = {
    PAGE_LOAD,
    ORDER_TRACK,
    ORDER_RETURN,
};
