"use strict";

const PLCC_FORM_START = {
    eventName: "plccFormStart",
    eventData: {}
};

const PLCC_FORM_SUBMIT = {
    eventName: "plccFormSubmit",
    eventData: {
        customer: {
            plccApplicationStatus : ""
        }
    }
};

module.exports = {
    PLCC_FORM_START,
    PLCC_FORM_SUBMIT
};
