"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const { 
    PAGE_LOAD,
    ORDER_TRACK,
    ORDER_RETURN,
} = require("../models/OrderDetail");

class OrderDetailsAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof OrderDetailsAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        super("OrderDetailsAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof OrderDetailsAnalytics
     */
    collect() {
        this.prepareDOMElements();
        this.getPageData();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        // this.pageInterceptedEvents();
        // this.pageDelayedEvents();
    }

    pageEvents() {
        this.addCollectionNativeEventListeners("click", document.getElementsByClassName("js-order-track"), () => this.prepareSingleEvent(ORDER_TRACK));
        this.addCollectionNativeEventListeners("click", document.getElementsByClassName("js-order-return"), () => this.prepareSingleEvent(ORDER_RETURN));
    }

    pageInterceptedEvents() {

    }

    pageDelayedEvents() {

    }
}

module.exports = OrderDetailsAnalytics;
