const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE
        },
        page: {
            ...PAGE_INSTANCE,
            type: "review",
        }
    }
};

// To review as step is complete in a separate page
const PRODUCT_REVIEW_COMPLETE = {
    eventName: "productReviewComplete",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

module.exports = {
    PAGE_LOAD,
    PRODUCT_REVIEW_COMPLETE,
};
