const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
    PRODUCT_DATA,
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        },
        order: {
            checkoutType: "",
        },
        page: {
            ...PAGE_INSTANCE,
            checkoutStep: "",
            loginLocation: "checkout",
            type: "checkout"
        },
        products: [
            {
                ...PRODUCT_DATA,
                fulfillmentMethod: "",
            }
        ]
    }
};

const CHECKOUT_STEP = {
    eventName: "checkoutStepChange",
    eventData: {
        page: {
            checkoutStep: "",
        }
    }
};

const EXIT_PAYPAL = {
    eventName: "paypalExit",
    eventData: {}
};

const ORDER_REVIEW_EDIT = {
    eventName: "orderReviewEdit",
    eventData: {}
};

const ORDER_REVIEW_CANCEL = {
    eventName: "orderReviewCancel",
    eventData: {}
};

const ACCOUNT_DRAWER = {
    eventName: "toggleAccountForm",
    eventData: {
        page: {
            loginLocation: "checkout",
            type: "",
        }
    }
};

const FORM_ERROR = {
    eventName: "formError",
    eventData: {
        fields: [],
        formName: "",
        type: "form error",
    }
};

const TOKENIZATION_RESPONSE = {
    eventName: "tokenizationResponse",
    eventData: {
        declineReason: "",
        value: ""
    }
};

const FRAUD_RESPONSE = {
    eventName: "fraudResponse",
    eventData: {
        declineReason: "",
        value: ""
    }
};

const PAYMENT_RESPONSE = {
    eventName: "paymentResponse",
    eventData: {
        declineReason: "",
        value: ""
    }
};

module.exports = {
    PAGE_LOAD,
    CHECKOUT_STEP,
    EXIT_PAYPAL,
    ORDER_REVIEW_EDIT,
    ORDER_REVIEW_CANCEL,
    FORM_ERROR,
    ACCOUNT_DRAWER,
    TOKENIZATION_RESPONSE,
    FRAUD_RESPONSE,
    PAYMENT_RESPONSE,
};
