"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const {
    PAGE_LOAD,
    // CART_ADD,
    // CART_ERROR,
} = require("../models/Favorites");

class FavoritesAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof FavoritesAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance", "js-a-page-favorites"];
        super("FavoritesAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof FavoritesAnalytics
     */
    collect() {
        this.prepareDOMElements();
        this.getPageData();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    pageEvents() {

    }

    pageInterceptedEvents() {

    }

    pageDelayedEvents() {

    }
}

module.exports = FavoritesAnalytics;
