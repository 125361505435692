const BaseAnalytics = require("../base/BaseAnalytics");

const {
    PLCC_FORM_START,
    PLCC_FORM_SUBMIT
} = require("../models/Plcc");

class PLCCAnalytics extends BaseAnalytics {
    constructor() {
        super("PLCCAnalytics");
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof PLCCAnalytics
     */
    collect() {
        this.pageInterceptedEvents();
    }

    /**
     * Initialize page intercepted events
     */
    pageInterceptedEvents() {
        this.addNativeEventListeners("plccFormStartEvent", window, event => this.plccFormStart(event));
        this.addNativeEventListeners("plccFormSubmitEvent", window, event => this.plccFormSubmit(event));
    }

    /**
     * PLCC Form start event
     * @param {Object} _event
     */
    plccFormStart(_event) {
        this.prepareSingleEvent(PLCC_FORM_START, {});
    }


    /**
     * PLCC Form submit event
     * @param {Object} event
     */
    plccFormSubmit(event) {
        const eventData = event.detail.customer;
        let domData = {
            plccApplicationStatus: eventData.plccApplicationStatus
        };

        this.prepareSingleEvent(PLCC_FORM_SUBMIT, domData);
    }
}

module.exports = PLCCAnalytics;
