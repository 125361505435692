// START - Partial event data based on scatered DOM Data
const PAGE_INSTANCE = {
    isProduction: "",
    serverDate: "",
    siteName: "world market",
};

const PAGE_CUSTOMER = {
    id: "",
    status: "",
    wmeAvailableRewards: [],
    wmeId: "",
    wmeNextReward: ""
};

const PAGE_CART = {
    cartId: "",
    cartTotalItems: "",
    cartValue: "",
};

const PAGE_STORE = {
    associateStoreId: "",
    distanceFromStore: "",
    storeName: "",
};

const PRODUCT_DATA = {
    collectionId: "",
    id: "",
    kitId: "",
    price: "",
    quantity: "",
    sku: ""
};
// END - Partial event data based on scatered DOM Data

// START - Page Data
const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE
        },
        page: {
            ...PAGE_INSTANCE,
            type: "",
        }
    }
};
// END - Page Data

// START - Search Data
const SEARCH_FORM_SUBMIT = {
    eventName: "searchFormSubmit",
    eventData: {
        search: {
            keyword: ""
        }
    }
};

const SEARCH_SUGGEST_CLICK = {
    eventName: "autoSuggestClick",
    eventData: {
        autoSuggestLink: "",
        autoSuggestTerm: "",
        autoSuggestType: ""
    }
};
// END - Search Data

// START - Store Locator Data
const STORE_SEARCH = {
    eventName: "storeLocatorSearch",
    eventData: {
        searchPhrase: "",
        searchResults: "",
        searchType: ""
    }
};

const STORE_CHANGE = {
    eventName: "changeStore",
    eventData: {
        name: "",
        previousName: ""
    }
};

// END - Store Locator Data

// START - Cart Data
const MINICART_OPEN = {
    eventName: "miniCartOpen",
    eventData: {
        products: [
            {
                ...PRODUCT_DATA
            }
        ]
    }
};

const CART_ADD = {
    eventName: "cartAdd",
    eventData: {
        products: [
            {
                addLocation: "",
                bopisType: "",
                fulfillmentMethod: "",
                pickupStore: "",
                ...PRODUCT_DATA
            }
        ]
    }
};

const CART_ADD_ERROR = {
    eventName: "cartAddError",
    eventData: {
        error: {
            type: ""
        }
    }
};
// END - Cart Data

// START - Favorites Data
const FAVORITES_ADD = {
    eventName: "favoritesAdd",
    eventData: {
        products: [
            {
                ...PRODUCT_DATA
            }
        ],
        favorites: {
            name: ""
        }
    }
};

const FAVORITES_ADD_ERROR = {
    eventName: "favoritesAddError",
    eventData: {
        error: {
            type: ""
        }
    }
};
// END - Favorites Data

// START - Account
const ACCOUNT_DRAWER = {
    eventName: "accountDrawerOpen",
    eventData: {
        page: {
            loginLocation: "header",
            type: "",
        }
    }
};

const LOGIN_FAILURE = {
    eventName: "loginFailure",
    eventData: {
        error: {
            fields: [],
            type: "login failure",
        }
    }
};

const JOIN_FAILURE = {
    eventName: "joinFailure",
    eventData: {
        error: {
            fields: [],
            formName: "",
            type: "",
        }
    }
};

const LOGIN_COMPLETE = {
    eventName: "loginComplete",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        }
    }
};

const ACCOUNT_CREATE = {
    eventName: "accountCreate",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
            signupFromStore: "",
            smsSignupChecked: "",
            startedInStore: "",
        }
    }
};

const ACCOUNT_LOGOUT = {
    eventName: "logoutClick",
    eventData: {}
};

const PASSWORD_RESET_START = {
    eventName: "passwordResetStart",
    eventData: {}
};

const PASSWORD_RESET_COMPLETE = {
    eventName: "passwordResetComplete",
    eventData: {}
};
// END - Account

// START - Email Form Overlay View
const EMAIL_SIGNUP_OVERLAY_VIEW = {
    eventName: "emailSignupOverlayView",
    eventData: {}
};
// END - Email Form Overlay View

// START - Global Elements
const EMAIL_SIGNUP = {
    eventName: "emailSignup",
    eventData: {}
};

const OFFER_CLICK = {
    eventName: "offerClick",
    eventData: {
        name: ""
    }
};

const PROMO_CODE_ADD = {
    eventName: "addPromoCode",
    eventData: {
        order: {
            promoCodes: [
                {
                    code: "",
                    name: "",
                    singleUsePromoCode: "",
                    type: "",
                }
            ]
        }
    }
};

const PROMO_CODE_REMOVE = {
    eventName: "removePromoCode",
    eventData: {
        order: {
            promoCodes: [
                {
                    code: "",
                    name: "",
                    singleUsePromoCode: "",
                    type: "",
                }
            ]
        }
    }
};

const PROMO_CODE_ERROR = {
    eventName: "promoCodeError",
    eventData: {
        error: {
            promoCode: "",
            type: "",
        }
    }
};
// END - Global Elements

module.exports = {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
    PRODUCT_DATA,
    PAGE_LOAD,
    SEARCH_FORM_SUBMIT,
    STORE_SEARCH,
    STORE_CHANGE,
    SEARCH_SUGGEST_CLICK,
    CART_ADD,
    CART_ADD_ERROR,
    EMAIL_SIGNUP_OVERLAY_VIEW,
    EMAIL_SIGNUP,
    MINICART_OPEN,
    FAVORITES_ADD,
    FAVORITES_ADD_ERROR,
    OFFER_CLICK,
    ACCOUNT_DRAWER,
    LOGIN_FAILURE,
    LOGIN_COMPLETE,
    JOIN_FAILURE,
    ACCOUNT_CREATE,
    ACCOUNT_LOGOUT,
    PASSWORD_RESET_START,
    PASSWORD_RESET_COMPLETE,
    PROMO_CODE_ADD,
    PROMO_CODE_REMOVE,
    PROMO_CODE_ERROR,
};
